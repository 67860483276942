import React from 'react';
import { Box, Typography, useTheme, useMediaQuery } from '@mui/material';
const logo = '/tycz-logo-glowing.png'

const Hero = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md')); // Adjust breakpoint as needed

  return (
    <Box sx={{
        display: 'flex',
        flexDirection: matches ? 'row' : 'column-reverse', 
        alignItems: 'center',
        justifyContent: 'center',
        height: '80vh',
        width: '100%',
        px: { xs: 1, sm: 2, md: 3 }, //
      }}>
      <Box sx={{
  flex: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  px: 4, 
}}>
  <Box sx={{
    maxWidth: '100%',
    boxShadow: 10, // Adjust the shadow 
    border: '2px solid black', // This adds the black border
    borderRadius: '300px',
    // transform: 'translateY(-8px)', // Lifts the video for the 3D effect
    transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out', // Smooth transition for the hover effect and box-shadow
    '&:hover': {
      transform: 'translateY(-10px)', // Optional: increases the lift when hovering for more of a 3D effect
      boxShadow: `
        0 0 10px #fff, /* outer white */
        0 0 20px #fff, /* middle white */
        0 0 30px #F3BA2F, /* inner bnb */
        0 0 40px #F3BA2F, /* inner bnb */
        0 0 50px #F3BA2F, /* inner bnb */
        0 0 60px #F3BA2F, /* inner bnb */
        0 0 70px #F3BA2F /* inner bnb */
      `,
    },
  }}>
    <img src={logo} alt="ThankYouCZ Logo" style={{ maxHeight: '300px' }} />
    
</Box>

</Box>

      <Box sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        // padding: theme.spacing(10),
        paddingTop: '40px'
      }}>
        <Typography variant="h3" component="h1" gutterBottom>
        Thank You CZ!
        </Typography>
        <Typography variant="h6">
        A Token of Appreciation for CZ and a Leap Towards Crypto-Education for All! "Essentially" a meme token with a cause
        </Typography>
      </Box>
    </Box>
  );
};

export default Hero;
