import React from 'react';
import { useAnimation } from './AnimationContext';
import { AnimatedBackground } from './AnimatedBackground';

const BackgroundController = () => {
  const { isAnimating } = useAnimation();

  return (
    <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: -1 }}>
      <AnimatedBackground $isAnimating={isAnimating}>
      </AnimatedBackground>
    </div>
  );
};

export default BackgroundController;
