// DonationGoal.tsx
import React from 'react';
import { Box, Typography, Paper } from '@mui/material';

const DonationGoal: React.FC = () => {
    return (
        <Paper elevation={3} sx={{ margin: '2rem', padding: '2rem', background: 'linear-gradient(145deg, #2196f3, #21cbf3)', color: '#fff' }}>
            <Box>
                <Typography variant="h4" component="h2" gutterBottom align="center" sx={{ fontWeight: 'bold' }}>
                    Our Pledge: $1 Million to Crypto Ed in the words of your crypto Bro🚀
                </Typography>
                <Typography variant="body1" paragraph>
                    Yo fam, we're on a moon mission with "ThankYou CZ" to drop mad crypto knowledge bombs across the globe. Inspired by the OG CZ and his blockchain wizardry, we're chucking a cool <strong>milli</strong> to Giggle Academy. That's right, this year, we hodl and donate like bosses.
                </Typography>
                <Typography variant="body1" paragraph>
                    But hey, it ain't about just throwing coins into a wishing well. It's staking a claim in the digital frontier for all the crypto kiddos out there. As TYCZ stacks up to that juicy <strong>$1 billion market cap</strong>, we're siphoning that sweet success right back into the gang that's got our backs. Your diamond hands make this happen!
                </Typography>
                <Typography variant="body1" paragraph>
                    Buckle up, crew. We're on this rocket ride together, carving out a decentralized dynasty where even noobs can nab the secrets of Satoshi. <strong>Jump on board</strong>, spread the word, and let's sling some serious BNB to school. We're here to give CZ his flowers and turn the crypto world up a notch. To infinity and beyond!
                </Typography>
            </Box>
        </Paper>
    );
};

export default DonationGoal;
