import React from 'react';
import { Box, Typography, Container } from '@mui/material';

const About: React.FC = () => {
  return (
    <Box my={5}>
      <Container maxWidth="md"> {/* Centers content and adds horizontal padding */}
        <Typography variant="h4" gutterBottom align="center">
          About TYCZ
        </Typography>
        <Typography variant="body1" paragraph align="justify">
          At the heart of the 'ThankYou CZ' project lies a dual mission: 
          to honor Changpeng Zhao (CZ)'s unparalleled contribution to the 
          crypto universe and to pave the way for future innovators through 
          supporting Giggle Academy. Inspired by CZ's belief in blockchain's power 
          to transform education, TYCZ isn't just a token; it's our way of saying 
          'thank you' and 'let's build a brighter future together  </Typography>
      </Container>
    </Box>
  );
};

export default About;
