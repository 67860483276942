import React from 'react';
import { Box, Typography, Container } from '@mui/material';
import ComingSoon from '../components/ComingSoon';

const Bridge: React.FC = () => {
  return (
    <>
    <ComingSoon title="Bridge" />
    <Box>
      <Container maxWidth="md"> 
        <Typography variant="body1" paragraph align="center">
          Come back to bridge your TYCZ from Solana to  BNB Chain to MajorL2's and Beyond </Typography>
      </Container>
    </Box>
    </>
  );
};

export default Bridge;
