import React from 'react';
import Hero from '../components/Hero';
import Roadmap from '../components/RoadMap';
import RoadmapHeader from '../components/RoadMapHeader';
// import PresaleCountdown from '../components/PresaleCountdown';
import DonationGoal from '../components/DonationalGoal';

const Home = () => {
  return (
    <div>
      {/* <PresaleCountdown /> */}
      <Hero />
      <DonationGoal />
      <RoadmapHeader />
      <Roadmap />
    </div>
  );
};

export default Home;
