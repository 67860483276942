import styled, { keyframes, css } from 'styled-components';


const animateGradient = keyframes`
  0%, 100% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
`;

export const AnimatedBackground = styled.div`
  background: linear-gradient(270deg, #F3BA2F, #000, purple, blue, pink);
  background-size: 400% 400%;
  ${({ $isAnimating }) => $isAnimating && css`
    animation: ${animateGradient} 15s ease infinite;
  `}
  width: 100%;
  height: 100vh;
`;

