import React, { useState } from 'react';
import { AppBar, Toolbar, Container, Switch, Box, Button, IconButton, Drawer, List, ListItem, ListItemText, Tooltip, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu'; // Import the hamburger icon
import ContentCopyIcon from '@mui/icons-material/ContentCopy'; // Import the copy icon
import AdjustIcon from '@mui/icons-material/Adjust'; // Import the adjust icon for animation
import { Link } from 'react-router-dom';
import { useAnimation } from '../pages/AnimationContext'; 

const Navbar = () => {
  const { toggleAnimation } = useAnimation();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const logo = '/tycz-logo.png';

  // Presale address and function to copy it to the clipboard
  const presaleAddress = '0x8dc89530Ed789ff545bD1053eCe69f50ef52f1d0';
  const truncatedAddress = `${presaleAddress.substring(0, 6)}...${presaleAddress.substring(presaleAddress.length - 4)}`;

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const copyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      // Display a tooltip, toast, or a message indicating the address has been copied
      // Example: alert("Address copied to clipboard!");
    } catch (err) {
      // Handle the error case
      // Example: alert("Failed to copy address");
    }
  };

  const menuItems = [
    { name: 'About', path: '/about' },
    { name: 'Bridge', path: '/bridge' },
    { name: 'Stake TYCZ', path: '/earn' },
    { name: 'LuckyDrawGame', path: '/luckdraw' }
  ];

  return (
    <AppBar position="static" sx={{
      background: 'transparent',
      boxShadow: 'none',
      color: (theme) => theme.palette.text.primary
    }}>
      <Container maxWidth="lg">
        <Toolbar disableGutters>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Drawer
            anchor="left"
            open={drawerOpen}
            onClose={handleDrawerToggle}
            sx={{
              display: { xs: 'block', sm: 'none' },
            }}
          >
            <Box
              sx={{ width: 250 }}
              role="presentation"
              onClick={handleDrawerToggle}
              onKeyDown={handleDrawerToggle}
            >
              <List>
                {menuItems.map((item) => (
                  <ListItem button key={item.name} component={Link} to={item.path}>
                    <ListItemText primary={item.name} />
                  </ListItem>
                ))}
              </List>
            </Box>
          </Drawer>
          <Link to="/" style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}>
            <img src={logo} alt="ThankYouCZ Logo" style={{ maxHeight: '100px', marginRight: '10px' }} />
          </Link>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            {menuItems.map((item) => (
              <Button color="inherit" component={Link} to={item.path} key={item.name}>
                {item.name}
              </Button>
            ))}
            <Tooltip title="Copy Presale Address" arrow>
              <IconButton
                onClick={() => copyToClipboard(presaleAddress)}
                aria-label="copy address"
                color="inherit"
                size="large"
                sx={{ ml: 2 }}
              >
                <Typography variant="body2" sx={{ minWidth: '100px', display: 'inline-block', textAlign: 'center', marginRight: '8px' }}>
                  {truncatedAddress}
                </Typography>
                <ContentCopyIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <IconButton onClick={toggleAnimation} aria-label="toggle animation" size="large">
            <AdjustIcon />
          </IconButton>
          {/* Here you can insert the theme switch button if you have one */}
          {/* <Switch checked={darkMode} onChange={handleThemeChange} /> */}
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Navbar;
