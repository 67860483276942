// App.tsx
import React from 'react';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { darkTheme } from './theme';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import Staking from './pages/Staking';
import LuckyDrawGame from './pages/LuckyDrawGame';
import { AnimationProvider } from './pages/AnimationContext';
import BackgroundController from './pages/BackgroundController'; // Adjust the import path as necessary
import Bridge from './pages/Bridge';
import About from './pages/About';
import { Helmet } from 'react-helmet';

function App() {
  return (
    <>
    <Helmet>
        <title>Thank You CZ! </title>
        <meta name="description" content="Thank You CZ" />
        <meta name="keywords" content="Thank You CZ" />
        <meta property="og:title" content="Thank You CZ" />
        <meta property="og:description" content="Thank You CZ" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://thankyoucz.com/tycz-logo.png" />
        <meta property="og:url" content="https://thankyoucz.com" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Thank You CZ." />
        <meta name="twitter:description" content="Thank You CZ." />
        <meta name="twitter:image" content="https://thankyoucz.com/tycz-logo.png" />
      </Helmet>
      <ThemeProvider theme={darkTheme}>
            <AnimationProvider>

        <CssBaseline />
        <BackgroundController /> 
        <Router>
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/earn" element={<Staking />} />
            <Route path="/about" element={<About />} />
            <Route path="/luckdraw" element={<LuckyDrawGame />} />
            <Route path="/bridge" element={<Bridge />} />
          </Routes>
          <Footer />
        </Router>
        </AnimationProvider>
      </ThemeProvider>
      </>
  );
}

export default App;
