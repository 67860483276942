import React from 'react';
import { Card, CardContent, Typography, Grid, List, ListItem, ListItemText } from '@mui/material';

type RoadmapContent = {
    subtitle: string;
    details: string;
};

type RoadmapPhase = {
    title: string;
    content: RoadmapContent[];
};

const roadmapPhases: RoadmapPhase[] = [
    {
        title: 'Blast Off 🚀 (Q2 2024)',
        content: [
            {
                subtitle: 'Meme Minting Madness',
                details: 'We drop the TYCZ token like it’s hot. Get in early, space cadets!',
            },
            {
                subtitle: 'The Hype Train',
                details: 'Telegram memes, Twitter storms, and TikTok dances. Let’s get this crypto party started!',
            },
            {
                subtitle: 'First Stack Drop',
                details: 'Slapping down a cool $10K in TYCZ tokens to Giggle Academy once we hit that sweet $1M market cap. Just the beginning, fam!',
            },
        ],
    },
    {
        title: 'To the Stratosphere 🌌 (Q2 2024)',
        content: [
            {
                subtitle: 'Mooning with the Exchanges',
                details: 'Landing TYCZ on the big-name exchanges because we’re going places, and we need more fuel.',
            },
            {
                subtitle: 'Bromance for Good',
                details: 'Linking arms with fellow crypto crusaders and ed-tech warriors to pump the mission.',
            },
            {
                subtitle: 'Level Up the Loot',
                details: 'Dropping another $20K to our brainy buds at Giggle Academy when we cruise past $2M market cap. Cha-ching!',
            },
        ],
    },
    {
        title: 'In Orbit 🛰️ (Q3 2024)',
        content: [
            {
                subtitle: 'Decentralize the Vibes',
                details: 'Kicking off the TYCZ DAO. It’s like a galactic council, but with more memes and less bureaucracy.',
            },
            {
                subtitle: 'Eternal Earnings',
                details: 'Introducing staking, so your stack grows while you sleep. Dream of Lambos and generosity.',
            },
            {
                subtitle: 'Continual Cash Cannon',
                details: 'Every time we blast past another $1M market cap, we up the ante and donate more to Giggle Academy. Because we’re not just mooning; we’re learning.',
            },
        ],
    },
    {
        title: 'Galaxy Brain Moves 🌠 (Q4 + 2025 and Beyond)',
        content: [
            {
                subtitle: 'Education Everywhere',
                details: 'We’re spreading the wealth, making it rain knowledge across the cosmos.',
            },
            {
                subtitle: 'Build, Bro, Build',
                details: 'Supporting projects that get us hyped about the future. Think of it as planting trees, but the trees are made of digital gold.',
            },
            {
                subtitle: 'CZ Day',
                details: 'It’s like a crypto holiday, but cooler. Celebrating the man, the myth, the legend with annual shenanigans that remind us why we’re here (and to press that donate button).',
            },
        ],
    }

];

const Roadmap: React.FC = () => {
    return (
        <Grid container spacing={4} sx={{ padding: 4 }}>
            {roadmapPhases.map((phase, index) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                    <Card raised sx={{ height: '100%', display: 'flex', flexDirection: 'column', backgroundImage: "linear-gradient(to right, #00d2ff, #3a7bd5, #4b0082)" }}>
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                {phase.title}
                            </Typography>
                            <List>
                                {phase.content.map((item, itemIndex) => (
                                    <ListItem alignItems="flex-start" key={itemIndex} sx={{ padding: 0 }}>
                                        <ListItemText
                                            primary={
                                                <Typography variant="h6" color="text.primary" gutterBottom>
                                                    {item.subtitle}
                                                </Typography>
                                            }
                                            secondary={
                                                <Typography variant="body2" color="text.secondary">
                                                    {item.details}
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        </CardContent>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );
};

export default Roadmap;
