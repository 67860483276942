import React from 'react';
import { Box, Container, Typography, IconButton, Link as MuiLink } from '@mui/material';
import { FaTwitter, FaInstagram, FaTelegram, } from 'react-icons/fa'; 

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const startYear = 2023;

  return (
    <Box component="footer" sx={{ bgcolor: 'transparent', py: 6 }}>
      <Container maxWidth="lg" sx={{ textAlign: 'center' }}>
        <Box sx={{ mb: 2 }}>
          {/* Social Icons */}
          <IconButton component="a" href="https://x.com/thankyoucztoken" aria-label="Twitter" target="_blank">
            <FaTwitter />
          </IconButton>
          <IconButton component="a" href="https://t.me/thankyoucz" aria-label="teleggram" target="_blank">
            <FaTelegram />
          </IconButton>
        </Box>
        <Typography variant="body2" color="text.secondary" align="center">
          © {startYear}-{currentYear} TYCZ. All rights reserved.
        </Typography>
        <Typography variant="body2" color="text.secondary" align="center">
          Contact us at: 
          <MuiLink href="mailto:thankyoucz.com@gmail.com" sx={{ ml: 0.5, color: 'green' }}>
          info@thankyoucz.com
          </MuiLink>
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;
