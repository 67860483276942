import React from 'react';
import { Box, Typography } from '@mui/material';

const RoadmapHeader: React.FC = () => {
  return (
    <Box
      sx={{
        marginTop: '10px',
        backgroundColor: 'transparent', 
        borderRadius: '4px',
        boxShadow: '0 4px 40px rgba(0,0,0,0.1)',
        marginBottom: '24px',
        padding: '10px'
      }}
    >
      <Typography variant="h4" component="h2" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
        Roadmap
      </Typography>
    </Box>
  );
};

export default RoadmapHeader;
