// theme.ts
import { createTheme } from '@mui/material/styles';

export const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#1976d2',
    },
    text: {
      primary: '#000000',
    },
    background: {
      default: 'linear-gradient(to left, #fff, #fff)',
    },
    // other color settings
  },
  // other theme overrides
});

export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#424242',
    },
    text: {
      primary: '#ffffff',
    },
    background: {
      default: '#000',
    },
    // other color settings
  },
  // other theme overrides
});
